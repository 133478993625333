import { environment } from '@environments/environment';
import {
  FfNgxEndpointDefinition,
  createApiConfiguration,
} from '@fagforbundet/ngx-components';
import { IdApiEndpoints } from '@config/apis/id/id-api-endpoints';

const idApiConfig = createApiConfiguration<typeof IdApiEndpoints>({
  baseUrl: environment.apis.id.baseUrl,
  endpoints: IdApiEndpoints,
});

export const idApiNoAuthEndpoints: FfNgxEndpointDefinition[] = [
  idApiConfig.endpoints.GET_AUTHORIZE,
  idApiConfig.endpoints.GET_COUNTRIES,
  idApiConfig.endpoints.GET_JWKS,
  idApiConfig.endpoints.GET_OPENID_CONFIGURATION,
  idApiConfig.endpoints.GET_PHONE_CODES,
  idApiConfig.endpoints.GET_TOKEN,
  idApiConfig.endpoints.GET_VALIDATE_EMAIL_ADDRESS,
  idApiConfig.endpoints.GET_VALIDATE_PHONE_NUMBER,
];

export default idApiConfig;
