import { FfNgxEndpointRecords } from '@fagforbundet/ngx-components';

export const IdApiEndpoints = {
  DELETE_EMAIL_ADDRESS: {
    path: '/v1/emails/:uuid',
  },
  DELETE_PHONE_NUMBER: {
    path: '/v1/phones/:uuid',
  },
  GET_AUTHORIZE: {
    path: '/v1/oauth/authorize',
  },
  GET_COUNTRIES: {
    path: '/v1/countries/:lang',
  },
  GET_JWKS: {
    path: '/.well-known/jwks',
  },
  GET_OPENID_CONFIGURATION: {
    path: '/.well-known/openid-configuration',
  },
  GET_PHONE_CODES: {
    path: '/v1/countries/:lang/phone-codes',
  },
  GET_POSTAL_CODE_LOOKUP: {
    path: '/v1/postal-codes/lookup/country/no/postal-code/:postalCode',
  },
  GET_SELF: {
    path: '/v2/users/self',
  },
  GET_TOKEN: {
    path: '/v1/oauth/token',
  },
  GET_VALIDATE_EMAIL_ADDRESS: {
    path: '/v1/validate/email/:emailAddress',
  },
  GET_VALIDATE_PHONE_NUMBER: {
    path: '/v1/validate/phone/:phoneNumber',
  },
  POST_EMAIL_ADDRESS: {
    path: '/v1/emails',
  },
  POST_PHONE_NUMBER: {
    path: '/v1/phones',
  },
  POST_SEND_EMAIL_ADDRESS_VERIFICATION_EMAIL: {
    path: '/v1/emails/:uuid/send-verification-email',
  },
  POST_SEND_PHONE_NUMBER_VERIFICATION_SMS: {
    path: '/v1/phones/:uuid/send-verification-sms',
  },
  POST_SET_PRIMARY_EMAIL_ADDRESS: {
    path: '/v1/emails/:uuid/set-primary',
  },
  POST_SET_PRIMARY_PHONE_NUMBER: {
    path: '/v1/phones/:uuid/set-primary',
  },
  POST_POSTAL_ADDRESS: {
    path: '/v1/addresses',
  },
  POST_VERIFY_EMAIL_ADDRESS: {
    path: '/v1/emails/:uuid/verify/:code',
  },
  POST_VERIFY_PHONE_NUMBER: {
    path: '/v1/phones/:uuid/verify/:code',
  },
  PUT_POSTAL_ADDRESS: {
    path: '/v1/addresses/:uuid',
  },
  PUT_SELF: {
    path: '/v1/users/self',
  },
} satisfies FfNgxEndpointRecords;
